import axios from "axios";
import { authHeader } from "@/services/auth-header";
import { baseURL } from "@/services/api";

const AuthApi = axios.create({
  baseURL: baseURL + "api/auth/",
  headers: {
    "Content-type": "application/json",
  },
});

export default {
  async login(email, password) {
    return await AuthApi.post("/login", {
      email: email,
      password: password,
    }).then((res) => {
      document.cookie = "user=" + JSON.stringify(res.data.user) + " ;path=/;SameSite=Lax";
      return true;
    });
  },
  async register(form) {
    return await AuthApi.post("register", form).then((res) => {
      if (res.data) {
        return this.login(form.email, form.password);
      }
    });
  },
  async checkEmailUnique(email) {
    return await AuthApi.post("checkEmailUnique", email);
  },
  async changePassword(oldPassword, newPassword) {
    //Ich weiß, dass die Anfrage absolut sinnlos ist, aber eslint hat gemotzt, dass ich die Variablen nicht nutze
    let pw = {
      old: oldPassword,
      new: newPassword,
    };
    return await AuthApi.post(`changePassword/`, pw, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async logout() {
    document.cookie = 'user=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";';
    return true;
  },

  async loginHubbel() {
    return await AuthApi.post("/loginHubbel").then((res) => {
      document.cookie = "user=" + JSON.stringify(res.data.user) + " ;path=/;SameSite=Lax";
      return true;
    });
  },
};
