<template>
  <div>
    <div class="custom-page" v-html="texts.pageText.textContent" />
    <div style="margin-top: -100px">
      <vue-qr id="qr" text="http://wuerzburg.de/smart-city" :size="160" :margin="15" />
      <p>http://wuerzburg.de/smart-city</p>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import API from "@/services/api";

export default {
  name: "AboutUs",
  components: { Footer },
  data() {
    return {
      texts: {
        pageText: { textName: "about-hubbel", textContent: "placeholder for db content" },
      },
    };
  },
  beforeMount() {
    (async () => {
      for (let text in this.texts) {
        await API.getTextByName(this.texts[text].textName).then((res) => {
          let textdb = res.data[0];
          this.texts[text].textContent = textdb.content;
        });
      }
    })();
  },
};
</script>
