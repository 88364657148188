<template>
  <v-app class="app app--hubbel">
    <Navbar ref="Navbar" :name="this.$route.name" :topic="this.$route.params.topic" />
    <div class="app__content">
      <router-view :key="$route.fullPath" @ProfilePictureChange="changeNavProfilePic" />
    </div>
    <vue-confirm-dialog class="vue-confirm-dialog--test" />
  </v-app>
</template>
<script>
import Navbar from "./components/Navbar";
import Api from "@/services/api";
import ScannerAPI from "@/services/scanner-api";
import AuthApi from "@/services/auth-api";
import { isLocalDebugOCR } from "./services/api";
  import { toNumber } from "@/services/utils";
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      lastTimeStamp: 0,
      polling: null,
    };
  },
  mounted() {
    this.polling = setInterval(() => {
      this.checkScanner();
    }, 3000);
    if (toNumber(process.env.VUE_APP_HEARTBEAT_INTERVAL) > 0) {
      this.polling = setInterval(() => {
        this.sendHeartbeat();
      }, 1000 * 60 * toNumber(process.env.VUE_APP_HEARTBEAT_INTERVAL));
    }
  },
  beforeMount() {
    //TODO This does not have a validation check other than CORS maybe.
    // Whoever visits the hubbel domain will be logged in as the hubbel account. However, they can not make posts, because that's not possible using the hubbel frontend without a raspberry pi.
    // Unless of course someone would send the POST requests directly. But worst case: Someone can send hubbel postcards without being at the hubbel
    AuthApi.loginHubbel();
  },
  methods: {
    changeNavProfilePic(pic) {
      this.$refs.Navbar.changeProfilePicture(pic);
    },

    checkScanner() {
      if (isLocalDebugOCR) {
        //ScannerAPI.getLastTimestampDebug().then((res) => {
          //const timestamp = res.timestamp;
        const timestamp = 3;
        console.log("Debug!")
          if (this.lastTimeStamp !== timestamp) {
            //if (this.lastTimeStamp !== 0) {
              this.$router.push("/createPostcard");
            //}
            this.lastTimeStamp = timestamp;
          }
        
      } else {
        ScannerAPI.getScannerStatus().then((res) => {
          const timestamp = res.data.last_postcard_timestamp;
          if (this.lastTimeStamp !== timestamp) {
            if (this.lastTimeStamp !== 0) {
              if (res.data.last_postcard_successful === false) {
                this.$confirm({
                  title: "Postkarte wenden",
                  message:
                    "Bitte wende die Postkarte und schiebe sie mit der beschriebenen Seite nach oben ein.",
                  button: {
                    yes: "OK",
                  },
                });
              }
              else {
              const route = this.$route.path;
              console.log("Route: " + route);
              if (route.includes("/Forum/")) {
                console.log("Ich erkenne das Thema!");
                const topic = route.replace("/Forum/", "");
                console.log("Topic: " + topic);
                Api.getTopicByName(topic).then((res) => {
                  const topic_id = res.data[0].topic_id;
                  console.log("Topic id: " + topic_id);
                  this.$router.push("/createPostcard/" + topic_id);
                }
                );
              } else {
                this.$router.push("/createPostcard");
                }
              }
            }
            this.lastTimeStamp = timestamp;
          }
          else if (res.data.scanner_state == "scanning") {
            setTimeout(() => {
              this.checkScanner();
            }, 500);
          }
        });
      }
    },
    sendHeartbeat() {
      const data = {
        identifier: process.env.VUE_APP_HEARTBEAT_IDENTIFIER,
        userAgent: navigator.userAgent,
        time: this.getNow(),
        URL: this.$route.fullPath,
      };
      Api.postHeartbeat(data);
    },
    getNow: function () {
      const dateTime = new Date();
      return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
    },
  },
};
</script>
