<template>
  <div class="qr-code-button-comp">
    <div class="qrcodebutton">
      <button id="mitnehmen" @click="openModal()">Postkarte mitnehmen</button>
    </div>
    <div class="modal-background">
      <div v-if="qrcodeShown" id="modal">
        <vue-qr id="qr" v-bind:text="link" size="130" margin="15" />
        <span class="close" @click="closeModal()"><v-icon>$x</v-icon></span>
        <p>Scannen Sie den QR-Code, um die Postkarte mobil mitzunehmen.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL } from "@/services/api";

export default {
  name: "QRCodeButton",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      qrcodeShown: false,
    };
  },
  computed: {
    link() {
      return baseURL + "Postcard/" + this.id;
    },
  },
  methods: {
    openModal() {
      this.qrcodeShown = true;
    },
    closeModal() {
      this.qrcodeShown = false;
    },
  },
};
</script>
