<template>
  <div class="custom-page tutorial-view">
    <div class="tutorial__content">
      <h2>Sie haben eine Frage oder möchten Feedback zu den Projekten geben?</h2>
      <span class="content">Dann befolgen Sie einfach die folgenden Schritte:</span>
      <v-container class="bv-example-row">
        <v-row id="step1" justify="space-around" align="center">
          <v-col class="col-4">
            <v-img src="../../../client-shared/img/tutorial_step1.jpg" tabindex="1"/>
          </v-col>
          <v-col class="col-4">
            <v-img src="../../../client-shared/img/tutorial_step2.jpg" tabindex="1" />
          </v-col>
          <v-col class="col-4">
            <v-img src="../../../client-shared/img/tutorial_step3.jpg" tabindex="1" />
          </v-col>
        </v-row>
        <v-row id="step1" justify="space-around" align="center">
          <v-col class="col-4">
            <span class="content">1. Nehmen Sie eine Postkarte aus dem Postkartenspender.</span>
          </v-col>
          <v-col class="col-4">
            <span class="content">2. Schreiben Sie Ihre Nachricht auf die Postkarte. Sie können außerdem Ihren Namen aufschreiben. Aber Achtung:
            Die Postkarte wird im Internet veröffentlicht. Wenn Sie anonym bleiben möchten, lassen Sie das Namensfeld einfach frei.</span>
          </v-col>
          <v-col class="col-4">
            <span class="content">
              3. Werfen Sie die Postkarte in den Briefkasten. Die Postkarte wird dort eingescannt und
              erscheint dann auf dem Bildschirm.
            </span>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
    name: "Help",
    components: { Footer },
    data: () => ({
      topicId: null
    }),

    mounted() {
      this.topicId = parseInt(this.$route?.params?.id) || null;
      console.log("Topic id im Tutorial " + this.topicId);
    },
};
</script>
