<template>
  <button v-if="isPrimary" class="btn primary_btn" type="button">
    {{ label_text }}
  </button>
  <button v-else-if="isIcon" class="btn icon_btn" type="button">
    <v-icon class="ma-2" :left="true">
      {{ !icon?.startsWith("mdi-") ? "$" : "" }}{{ icon }}
    </v-icon>
    {{ label_text }}
  </button>
  <button v-else-if="isAction" class="btn action_btn" type="button">
    <v-icon> {{ !icon?.startsWith("mdi-") ? "$" : "" }}{{ icon }} </v-icon>
  </button>
  <v-btn v-else-if="isCross" class="ma-2" color="dark grey" text icon>
    <v-icon>{{ !icon?.startsWith("mdi-") ? "$" : "" }}{{ icon ? icon : "x" }} </v-icon>
  </v-btn>
  <button v-else-if="isBack" class="btn back_btn" type="button">
    <v-icon class="pt-4 pb-4"> $arrowLeft </v-icon>
    zurück
  </button>
  <button v-else-if="isAnhang" class="btn anhang_btn" type="button">
    <v-icon class="mr-2 mb-1"> {{ !icon?.startsWith("mdi-") ? "$" : "" }}{{ icon }} </v-icon>
    {{ label_text }}
  </button>
  <button v-else-if="isDelete" class="btn delete_btn">
    {{ label_text }}
  </button>
</template>

<script>
export default {
  props: {
    label_text: {
      type: String,
    },
    isPrimary: {
      type: Boolean,
      default: false,
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
    isBack: {
      type: Boolean,
      default: false,
    },
    isAction: {
      type: Boolean,
      default: false,
    },
    isCross: {
      type: Boolean,
      default: false,
    },
    isAnhang: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    isDelete: {
      type: Boolean,
      dafault: false,
    },
  },
  type: String,

  link: {
    type: String,
    default: "",
  },
  methods: {
    linkTo() {
      if (this.link !== "") this.$router.push(this.link);
    },
  },
};
</script>
