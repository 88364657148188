<template>
  <div class="custom-page">
    <h1>Allgemeine Nutzungsbedingungen</h1>
    <div class="custom-page__content">
      <p>
        <b>
          Mit der Anmeldung und Nutzung von hubbel-mitmachen.de akzeptieren Sie unsere
          nachfolgenden Nutzungsbedingungen.
        </b>
      </p>
      <p><b>Registrierung</b></p>
      <p>
        Jede natürliche Person kann sich auf hubbel-mitmachen.de registrieren. Dabei wird ein
        Nutzer*innenname gewählt, der auf der Plattform öffentlich sichtbar ist. Wir bitten darum,
        dass der vollständige Klarname zu erkennen ist, dieses ist jedoch freiwillig. Des Weiteren
        ist die Angabe des Vor- und Nachnamens erforderlich, die nicht öffentlich einsehbar sind.
        Die Postleitzahl und Ortsangabe können freiwillig hinzugefügt werden, auch hier bitten wir
        um Ehrlichkeit. Mit der Registrierung akzeptieren die Nutzer*innen die
        Datenschutzbestimmungen und erhalten eine E-Mail, um das Nutzer*innenkonto zu bestätigen.
      </p>
      <p>
        Für jede natürliche Person ist nur eine Registrierung zulässig. Eine stellvertretende
        Registrierung für Dritte ist unzulässig. Der Betreiber behält sich vor, Nutzungsverhältnisse
        fristlos zu kündigen, wenn sie auf mehrfacher Registrierung derselben natürlichen Person
        oder auf Stellvertretung für einen Dritten beruhen.
      </p>
      <p>Die Nutzung von hubbel-mitmachen.de ist unentgeltlich.</p>
      <p><b>Erstellung von Inhalten</b></p>

      <p>
        Nutzer*innen können, soweit die Möglichkeit technisch gegeben wird, Vorschläge auf
        hubbel-mitmachen.de erstellen. Sie können Vorschläge kommentieren und Diskussionsthemen
        dazu eröffnen. Auf diese können wiederum Antworten als Kommentare erstellt werden.
      </p>
      <p>
        Für die Beiträge und die generelle Diskussionskultur gilt unsere Netiquette. Insbesondere
        sind Beleidigungen, Bedrohungen, Schimpfwörter, rassistische, sexistische oder
        pornographische sowie andere straf- oder zivilrechtlich relevante Äußerungen nicht erlaubt.
      </p>
      <p><b>Haftung für Inhalte</b></p>

      <p>
        Inhalte werden automatisch veröffentlicht. Der Betreiber prüft erstellte Inhalte und Daten
        vor Veröffentlichung nicht auf inhaltliche Angemessenheit, sachliche Richtigkeit oder auf
        etwaige Rechtsverstöße. Der Betreiber geht konkreten Hinweisen auf problematische Inhalte
        und Daten unverzüglich nach.
      </p>
      <p><b>Rechte an Inhalten</b></p>

      <p>
        Nutzer*innen versichern mit dem Hochladen von Bildmaterial, dass sie die Rechte an dem
        Material besitzen oder nennen die Quelle. Nutzer*innen räumen dem Betreiber mit dem
        Erstellen von Inhalten ein unbeschränktes, unwiderrufliches und übertragbares Nutzungsrecht
        an den jeweiligen Inhalten ein.
      </p>
      <p>
        Nutzer*innen stellen den Betreiber von allen Ansprüchen frei, die Dritte gegenüber dem
        Betreiber wegen etwaiger Rechtsverletzungen durch die eingestellten Inhalte erheben. Der
        Freistellungsanspruch umfasst auch die in einem solchen Zusammenhang erforderlich werdenden
        Kosten einer angemessenen Prüfung und ggf. Rechtsverteidigung, es sei denn, dass die
        Nutzer*innen die Beanstandung des Dritten nicht zu vertreten haben.
      </p>
      <p><b>Abstimmungen über Inhalte der Nutzer*innen</b></p>

      <p>
        hubbel-mitmachen.de wird genutzt um Abstimmungen, insbesondere über von den Nutzer*innen
        erstellte Inhalte, durchzuführen. Diese dienen einem Auswahlprozess. Eine rechtliche
        Verpflichtung gegenüber den Nutzer*innen, der so ausgewählten Inhalte, entsteht durch diese
        Abstimmungen nicht.
      </p>
      <p><b>Beendigung des Nutzungsverhältnisses</b></p>

      <p>
        Nutzer*innen können das Nutzungsverhältnis jederzeit gegenüber dem Betreiber kündigen. Bis
        dahin eingeräumte Rechte sind davon nicht berührt, diese gelten fort. Gesetzlich
        unabdingbare Rechte der Nutzer*innen, etwa auf Rückruf seiner Rechte, bleiben unberührt.
      </p>
      <p>
        Die Laufzeit von hubbel-mitmachen.de ist unbestimmt. Der Betrieb der Plattform kann
        jederzeit vom Betreiber eingestellt werden.
      </p>
    </div>
    <Footer />
  </div>
</template>

<script>
  import Footer from "@/components/Footer";

  export default {
    name: "TermsOfUse",
    components: { Footer },
  };
</script>
