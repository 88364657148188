<template>
  <form class="shadow postcard-comp" action="">
    <div class="postcard__wrapper">
      <div v-if="!attachmentShown" class="postcard__front">
        <div class="postcard__left">
          <div>
            <p class="postcard__message font-regular">
              <span v-if="postcard_info.approved || isInWork">{{ postcard_info.text }} </span>
              <span v-else class="dummyText">
                {{ dummyText }}
              </span>
            </p>
          </div>
        </div>
        <div class="postcard__vertical-line" />
        <div class="postcard__right">
          <div class="postcard__date">
            <ul>
              <li>
                {{ getDateString(postcard_info.date) }}
              </li>
              <li>
                <v-img
                  v-if="
                    postcard_info.readOnly &&
                    ['PC', 'Phone', 'Mailbox'].includes(postcard_info.sentFrom)
                  "
                  :alt="'Created from ' + postcard_info.sentFrom"
                  contain
                  :src="
                    require(`../../../client-shared/img/stamp_${postcard_info.sentFrom.toLowerCase()}.svg`)
                  "
                  class="date__img date__img--stamp"
                />
                <v-img
                  alt="hubbel Logo"
                  contain
                  src="../../../client-shared/img/hubbel_logo.svg"
                  class="date__img date__img--logo"
                />
              </li>
            </ul>
          </div>
          <div class="postcard__sender">
            <ul>
              <li>Absender*in</li>
              <li>
                <div>
                  <!-- TODO when someone writes a postcard, the postcard already has to know if the user is verified to show this here -->
                  <b :class="'verified--' + postcard_info.sender_verified"
                    >{{ postcard_info.sender }}
                    <v-img
                      v-if="postcard_info.sender_verified"
                      class="verified-icon"
                      src="../../../client-shared/img/verification_hubbel_v01.svg"
                      alt="(verified)"
                      width="18"
                      tabindex="1"
                    />
                  </b>
                </div>
              </li>
            </ul>
          </div>
          <div v-if="this.postcard_info.subject" class="postcard__subject">
            <ul>
              <br />
              <li>Thema:</li>
              <li>
                <div>
                  <router-link :to="`/Forum/${postcard_info.subject}`" class="subject__link">
                    <b>{{ postcard_info.subject }}</b>
                  </router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="attachmentShown" class="postcard__back">
        <Attachment
          :attachments="attachments"
          :postcard_id="postcard_info.id"
          :read-only="postcard_info.readOnly"
          @deleteAttachment="deleteAttachment"
          @openInput="openInput()"
        />
      </div>
    </div>
    <Button
      v-if="!postcard_info.readOnly || (attachments.length > 0 && postcard_info.approved)"
      id="attachmentSwitcher"
      :is-anhang="true"
      icon="paperclip"
      :label_text="
        attachmentShown
          ? 'Postkarte anzeigen'
          : attachments.length === 0
          ? 'Anhang hinzufügen'
          : ' Postkarte anzeigen'
      "
      class="postcard__attachment-switcher"
      @click.native="toggleAttachment($event)"
    />
    <input
      v-if="!postcard_info.readOnly"
      id="fileChooser"
      type="file"
      class="fileChooser"
      accept="image/*,.pdf"
      multiple
      @change="uploadAttachments()"
    />
  </form>
</template>

<script>
import Button from "@/components/Button";
import Attachment from "@/components/Attachment";
import { baseURL } from "@/services/api";

export default {
  name: "PostCard",
  components: {
    Attachment,
    Button,
  },
  props: {
    postcard_info: { type: Object },
    isInWork: {
      type: Boolean,
    },
  },
  data: () => ({
    writtenText: null,
    attachmentShown: false,
    attachments: [],
    qrcodeShown: false,
  }),

  computed: {
    linkToPostcard() {
      return baseURL + "Postcard/" + this.postcard_info.id;
    },
    dummyText() {
      return "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.";
    },
  },

    created() {
    this.attachments = this.postcard_info.attachments
      ? this.renderAttachments(this.postcard_info.attachments)
      : [];
  },
  methods: {
    getText() {
      return this.writtenText || "";
    },
    getDateString(cardDate) {
      const date = new Date(cardDate.replace(/\s/, "T") + "Z"); // https://stackoverflow.com/questions/21883699/safari-javascript-date-nan-issue-yyyy-mm-dd-hhmmss
      let dateString;

      dateString =
        ("0" + date.getDate()).slice(-2) +
        "." +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "." +
        date.getFullYear();

      return dateString;
    },
    getAttachments() {
      return this.attachments;
    },
    renderAttachments(attachments) {
      const att = [];
      attachments.forEach((attach) => {
        att.push({
          name: attach.name,
          type: attach.static_file_path.split(".").pop(),
          src: baseURL + "public/" + attach.static_file_path,
        });
      });
      return att;
    },
    pushRenderedAttachments(attachments) {
      this.attachments = attachments;
    },
    openInput() {
      document.getElementById("fileChooser").click();
    },
    uploadAttachments() {
      let fileList = document.getElementById("fileChooser").files;
      const attachments = this.attachments;

      if (!fileList) return;

      for (let i = 0; i < fileList.length; i++) {
        //const serverUrl = uploadToServer(fileList[i]);

        const fileReader = new FileReader();
        fileReader.readAsDataURL(fileList[i]);
        fileReader.addEventListener("load", function () {
          attachments.push({
            name: fileList[i].name,
            type: fileList[i].type.split("/")[1],
            //src: serverUrl
            src: this.result,
          });
        });
      }
      this.attachmentsVisualFeedback();
    },

    deleteAttachment(index) {
      this.attachments.splice(index, 1);
    },

    toggleAttachment(event) {
      event.stopPropagation();
      this.attachmentShown = !this.attachmentShown;
      if (
        this.attachmentShown &&
        this.attachments.length > 0 &&
        this.postcard_info.id !== undefined &&
        this.postcard_info.id !== null &&
        typeof this.postcard_info.id !== "undefined"
      ) {
        window._paq.push([
          "trackEvent",
          "Attachments",
          "Attachments Click",
          "Attachment Section opened.",
        ]);
      }
    },

    attachmentsVisualFeedback() {
      const switcher = document.getElementById("attachmentSwitcher");
      const initialColor = switcher.style.backgroundColor;
      const hoverColor = "#c5e388";

      switcher.style.backgroundColor = hoverColor;

      setTimeout(() => {
        switcher.style.backgroundColor = initialColor;
      }, 200);
      setTimeout(() => {
        switcher.style.backgroundColor = hoverColor;
      }, 400);
      setTimeout(() => {
        switcher.style.backgroundColor = initialColor;
      }, 600);
    },

    closeModal() {
      this.qrcodeShown = false;
    },

    openModal() {
      this.qrcodeShown = true;
    },
  },
};
</script>
