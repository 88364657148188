<template>
  <v-container
    class="color-light-grey pt-3 pb-3 footer-comp"
    :class="{ 'footer-comp--bottom': bottom }"
    fluid="fluid"
  >
    <v-row>
      <v-col cols="12" sm="4">
        <router-link to="/">
          <img
            src="../../../client-shared/img/hubbel_logo.svg"
            height="80"
            class="svg-color"
            alt="Logo von Hubbel"
          />
        </router-link>
      </v-col>
      <v-col>
        <router-link to="/impressum">
          <p class="font-dark-grey font-medium">Impressum</p>
        </router-link>
        <router-link to="/nutzungsbedingungen">
          <p class="font-dark-grey font-medium">Nutzungsbedingungen</p>
        </router-link>
      </v-col>
      <v-col>
        <router-link to="/about">
          <p class="font-dark-grey font-medium">Über Uns</p>
        </router-link>
        <router-link to="/datenschutz">
          <p class="font-dark-grey font-medium">Datenschutzerklärung</p>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Footer",
  props: {
    bottom: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
