<template>
  <v-app-bar app class="navbar-comp navbar-comp--hubbel">
    <router-link to="/">
      <v-img alt="hubbel Logo"
             class="shrink mr-2"
             contain
             src="../../../client-shared/img/zurueck-start.png"
             transition="scale-transition"
             width="120"
             @click="$router.push('/')" />
    </router-link>
    <div class="navbar__title">{{topic}}</div>
    <v-spacer />
    <v-toolbar-items class="d-none d-md-flex navbar__items">
      <MenuItem v-for="item in menu"
                :key="item.to"
                :to="item.to"
                :title="item.title"
                :class="{ active: name === item.active }" />
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import MenuItem from "./MenuItem";
import { getLoggedInUser } from "@/services/loggedIn";
import Api, { baseURL } from "@/services/api";
import authApi from "@/services/auth-api";
import config from "@/config.json";

export default {
  name: "Navbar",
  components: {
    MenuItem,
  },
  props: {
    name: {
      type: String,
    },
    topic: {
      type: String,
    },
  },
  data: () => ({
    isOpen: false,
    isLoggedIn: true,
    profilePicture: null,
    first_name: "",
    menu: config.menu,
  }),
  created() {
    let tempUser = getLoggedInUser();
    if (tempUser) {
      this.getProfilePicture();
      this.first_name = JSON.parse(tempUser).first_name;
    }
  },
  methods: {
    changeProfilePicture(pic) {
      this.profilePicture = pic;
    },
    loggedIn() {
      return getLoggedInUser();
    },
    getProfilePicture() {
      Api.getProfilePicture().then((result) => {
        if (result.split(/[/\\]/gm)[0] === "profilePictures") {
          this.profilePicture = baseURL + "public/" + result;
        } else this.profilePicture = result;
      });
    },
    logout() {
      authApi.logout();
    },
  },
};
</script>
