<template>
  <div class="custom-page">
    <h1>Datenschutzbestimmungen</h1>
    <div class="custom-page__content">
      <p><b>Informationen zur Datenverarbeitung gem. Art. 13 DSGVO</b></p>
      <p>
        Die Stadt Würzburg nimmt den Schutz Ihrer personenbezogenen Daten sehr ernst. Wir möchten
        Ihnen mit dieser Datenschutzerklärung einen Überblick darüber geben, wie die Stadt Würzburg
        den Schutz Ihrer Daten gewährleistet, welche Art von Daten zu welchem Zweck erhoben werden
        und wie sie verwendet werden. Im Zuge der Weiterentwicklung der Bürgerbeteiligungsaltform
        mitmachen-wuerzburg.de der Stadt Würzburg und der Implementierung neuer Technologien können
        auch Änderungen dieser Datenschutzerklärung erforderlich werden.
      </p>
      <p><b>Verantwortlich für die Verarbeitung Ihrer Daten</b></p>
      <p>
        Stadt Würzburg<br />
        Rückermainstraße 2<br />
        97070 Würzburg<br />
        Telefon: 09 31/ 37-0<br />
        Fax: 09 31/ 37- 33 73<br />
        E-Mail: info@stadt.wuerzburg.de
      </p>

      <p><b>Kontaktdaten der behördlichen Datenschutzbeauftragten</b></p>
      <p>
        Sie erreichen die behördliche Datenschutzbeauftragte unter:<br />
        Stadt Würzburg<br />
        Behördliche Datenschutzbeauftragte<br />
        Rückermainstraße 2<br />
        97070 Würzburg<br />
        Telefon: 09 31/ 37–0<br />
        E-Mail: datenschutz@stadt.wuerzburg.de
      </p>

      <p><b>Personenbezogene Daten</b></p>
      <p>
        Personenbezogene Daten sind Informationen, die dazu genutzt werden können, Ihre Identität zu
        erfahren. Darunter fallen Informationen wie Ihr Name, Adresse, Postanschrift, Telefonnummer.
      </p>
      <p>
        Informationen, die nicht mit Ihrer Identität in Verbindung gebracht werden (wie zum Beispiel
        Anzahl der Nutzer der Seite), fallen nicht darunter. Sie können unser Online-Angebot
        grundsätzlich ohne Offenlegung Ihrer Identität nutzen.
      </p>
      <p>
        Personenbezogene Daten werden erfasst, wenn Sie uns diese von sich aus mitteilen,
        beispielsweise wenn Sie mit uns in Kontakt treten. Dies ist erforderlich, um Ihr Anliegen
        bearbeiten zu können. Die uns auf diese Weise übermittelten personenbezogenen Daten werden
        wir selbstverständlich ausschließlich für den Zweck verwenden, zu dem Sie uns diese bei der
        Korrespondenz zur Verfügung stellen. Eine Mitteilung dieser Angaben erfolgt ausdrücklich auf
        freiwilliger Basis und mit Ihrer Einwilligung. Entsprechende Angaben werden dabei auf
        besonders geschützten Servern in Deutschland gespeichert. Der Zugriff darauf ist nur
        wenigen, für die technisch-administrative Betreuung der Server zuständigen befugten Personen
        möglich.
      </p>
      <p>Dies trifft zu bei:</p>
      <ul>
        <li>Anfragen via E-Mail oder Kontaktformular</li>
        <li>Vor- und Nachname</li>
        <li>Wohnort</li>
        <li>E-Mail-Adresse</li>
        <li>Text</li>
        <li>Newsletter-Abonnements</li>
        <li>Frau/Herr</li>
        <li>Vor- und Nachname</li>
        <li>Firma/Institution</li>
        <li>E-Mail-Adresse</li>
      </ul>
      <br />
      <p>
        Bei jedem Zugriff eines Nutzers auf eine Seite aus dem Angebot der Stadt Würzburg und bei
        jedem Abruf einer Datei werden Daten über diesen Vorgang in einer Protokolldatei
        gespeichert.
      </p>

      <p>Im Einzelnen wird über jeden Abruf folgender Datensatz gespeichert:</p>
      <ul>
        <li>Name der abgerufenen Datei,</li>
        <li>Datum und Uhrzeit des Abrufs,</li>
        <li>übertragene Datenmenge,</li>
        <li>Meldung, ob der Abruf erfolgreich war,</li>
        <li>Beschreibung des Typs des verwendeten Webbrowsers</li>
        <li>IP-Adresse</li>
      </ul>

      <br />
      <p>
        Wir erheben und verarbeiten diese Daten, um den störungsfreien Betrieb unserer Webseite
        sicherzustellen und einen Missbrauch unserer Leistungen erkennen, abwehren und verfolgen zu
        können. Ferner nutzen wir die erhobenen Daten zu statistischen Zwecken, um etwa auszuwerten,
        mit welchen Endgeräten und Browsern unsere Plattform aufgerufen wird, um auf dieser Basis
        unser Angebot an die Bedürfnisse der Nutzer anzupassen und zu verbessern. Die gespeicherten
        Daten werden nur zu statistischen Zwecken ausgewertet. Eine Weitergabe an Dritte findet
        nicht statt.
      </p>

      <p><b>Einsatz von Cookies</b></p>
      <p>
        Bei der Nutzung des Internetangebots werden sogenannte Cookies eingesetzt. Cookies sind
        kleine Text-Informations-Dateien, die von unserer Internetseite gesendet und von Ihrem
        Browser während des Zugriffs auf Ihrem Computer abspeichert werden. Sie können die Nutzung
        von Cookies unterbinden, indem Sie die Cookie-Funktion in Ihrem Browser deaktivieren. Wenn
        Sie dies tun, können Sie jedoch gegebenenfalls nicht sämtliche Funktionen des
        Internetangebotes der Stadt Würzburg vollumfänglich nutzen. Wie Sie die Nutzung von Cookies
        unterbinden, entnehmen Sie bitte der Anleitung Ihres Browser-Herstellers.
      </p>

      <p><b>Weitergabe personenbezogener Daten an Dritte</b></p>
      <p>
        Wir verwenden Ihre personenbezogenen Informationen im Falle von Informationsbestellungen nur
        innerhalb der Stadt Würzburg. Wir geben sie nicht ohne Ihre ausdrückliche Einwilligung an
        Dritte weiter. Soweit wir gesetzlich oder per Gerichtsbeschluss dazu verpflichtet sind,
        werden wir Ihre Daten an auskunftsberechtigte Stellen übermitteln.
      </p>

      <p><b> Links zu Webseiten anderer Anbieter</b></p>
      <p>
        Das Online-Angebot der Stadt Würzburg enthält Links zu Webseiten anderer Anbieter. Wir haben
        keinen Einfluss darauf, dass diese Anbieter die Datenschutzbestimmungen einhalten.
      </p>

      <p><b>Ihre Rechte</b></p>
      <p>
        Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende Rechte nach Art. 15 bis
        22 DSGVO zu: Recht auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung, auf
        Datenübertragbarkeit, Widerruf und Widerspruch. Beschwerderecht bei der Aufsichtsbehörde:
        Sie haben gemäß Art. 77 DSGVO zudem das Recht, sich bei der Aufsichtsbehörde zu beschweren,
        wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht
        rechtmäßig erfolgt. Die Anschrift der zuständigen Aufsichtsbehörde lautet: der bayerische
        Landesbeauftragte für den Datenschutz (BayLfD)Postanschrift: Postfach 22 12 19, 80502
        München
      </p>
      <p>
        Adresse: Wagmüllerstraße 18, 80538 München<br />
        Telefon: 089/ 212672-0<br />
        Fax: 089/ 212672-50<br />
        E-Mail: poststelle@datenschutz-bayern.de
      </p>
      <p><b>Kontaktdaten</b></p>
      <p>Sie erreichen uns unter folgenden Kontaktdaten:</p>
      <p>
        Stadt Würzburg<br />
        Fachbereich Wirtschaft, Wissenschaft und Standortmarketing<br />
        Rückermainstr. 2<br />
        97070 Würzburg<br />
        E-Mail: wws@stadt.wuerzburg.de
      </p>

      <p>
        Wir nehmen für die Betreuung unserer Webseite (Technische Pflege und Updates) die Leistungen
        der Stadt Würzburg, Rückermainstr. 2, 97070 Würzburg
      </p>
      <p>
        E-Mail: wws@stadt.wuerzburg.de als Auftragsverarbeiter gemäß Artikel 28 DSGVO in Anspruch.
      </p>
      <p>
        Wir nehmen für das Hosting unserer Webseite die Leistungen der der Stadt Würzburg,
        Rückermainstr. 2, 97070 Würzburg als Auftragsverarbeiter gemäß Artikel 28 DSGVO in Anspruch.
      </p>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "DataPrivacy",
  components: { Footer },
};
</script>
