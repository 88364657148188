import axios from "axios";
import BackendAPI from "@/services/api";

export const baseURL = process.env.VUE_APP_SCANNER_URL_PORT;

const Api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-type": "application/json",
  },
});

export default {
  async getLastTimestamp() {
    return Api.get("last_postcard_timestamp");
  },

  async getLatestPostcard() {
    return Api.get("last_postcard");
  },
  
  async getScannerStatus() {
    return Api.get("postcard_scanner_status");
  },

  async rejectPostcard() {
    return Api.get("reject_postcard");
  },

  async acceptPostcard() {
    return Api.get("accept_postcard");
  },

  async getLastTimestampDebug() {
    return BackendAPI.getDebugPostcardTimestamp().then((res) => {
      const timestamp = res.data.created_time;
      return { timestamp };
    });
  },
  async getLatestPostcardDebug() {
    return BackendAPI.getDebugPostcard().then((res) => {
      const base64 = res.data.base64;
      return { base64 };
    });
  },
  async returnPostcardDebug() {
    return "Returned debug postcard successfully";
  },
};
