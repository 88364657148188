<template>
  <div class="custom-page">
    <h1>Impressum</h1>
    <div class="custom-page__content">
      <p>
        Die Stadt Würzburg ist eine Körperschaft des Öffentlichen Rechts. Sie wird vertreten durch
        den Oberbürgermeister Christian Schuchardt.
      </p>

      <p><b>Herausgeber:</b></p>
      <p>
        Stadt Würzburg<br />
        Rückermainstraße 2<br />
        97070 Würzburg<br />
        Telefon 09 31/ 37 0<br />
        Telefax 09 31/ 37 33 73<br />
        E-Mail: info@stadt.wuerzburg.de
      </p>

      <p><b>Projektleitung:</b></p>
      <p>
        Stadt Würzburg<br />
        Fachbereich Wirtschaft, Wissenschaft und Standortmarketing Rückermainstraße 2,<br />
        97070 Würzburg<br />
        Fachbereichsleiter: Dipl.-Geograph Klaus Walther E-Mail: wws@stadt.wuerzburg.de<br />
        Umsatzsteuer-Identifikationsnummer gemäß §27a<br />
        Umsatzsteuergesetz: DE 134 188 711
      </p>
      <p>Informationen zur Stadt Würzburg entnehmen Sie bitte www.wuerzburg.de.</p>

      <p><b>Technische Betreuung und Gestaltung:</b></p>
      <p>
        Stadt Würzburg, Rückermainstraße 2, 97070 Würzburg<br />

        E-Mail: wws@stadt.wuerzburg.de
      </p>

      <p><b>Datenschutz:</b></p>
      <p>
        Stadt Würzburg<br />
        Behördliche Datenschutzbeauftragte<br />
        Rückermainstraße 2<br />
        97070 Würzburg<br />
        Telefon: 09 31/ 37–0<br />
        E-Mail: datenschutz@stadt.wuerzburg.de
      </p>

      <p><b>Haftungsausschluss</b></p>
      <p>
        Wir bemühen uns, auf dieser Website richtige und vollständige Informationen zur Verfügung zu
        stellen. Wir übernehmen jedoch keine Haftung oder Garantie für die Aktualität, Richtigkeit
        oder Vollständigkeit der auf dieser Seite bereitgestellten Informationen. Namentlich
        gezeichnete Beiträge geben nicht zwangsläufig die Meinung des Herausgebers wieder. Wir haben
        auf unserer Seite Links zu anderen Seiten im Internet gelegt. Für alle diese Links gilt: Für
        den Inhalt von Seiten, auf die verlinkt wurde, sind ausschließlich die entsprechenden
        Firmen, Organisationen und Personen verantwortlich. Wir erklären ausdrücklich, dass wir
        keinen Einfluss auf die Gestaltung und die Inhalte der verlinkten Seiten haben. Deshalb
        distanzieren wir uns hiermit ausdrücklich von allen Inhalten aller verlinkten Seiten und
        machen uns Ihre Inhalte nicht zu Eigen. Diese Erklärung gilt für alle auf unserer Homepage
        existierenden Links.
      </p>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "Impressum",
  components: { Footer },
};
</script>
