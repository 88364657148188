<template>
  <div class="start-view start-view--hubbel">
    <div class="start__head shadow true color-white">
      <div class="head__greeting" v-html="texts.Title.textContent" />
    </div>
    <div class="start__introduction" v-html="texts.Introduction.textContent" />
    <div v-if="topicsAreLoading" class="loading-screen">Themen werden geladen...</div>
    <div v-if="!topicsAreLoading" class="start__cards">
      <Card
        v-for="topic in this.getPinnedTopics()"
        :id="topic.id"
        :key="topic.id"
        :title="topic.title"
        :subheading="topic.subheading"
        :last_post="
          topic.last_post ? 'Letzte Postkarte vor ' + topic.last_post : 'Noch keine Postkarten'
        "
        :topic_age="topic.topic_age"
        :pinned="true"
        last
        @click.native="$router.push('/Forum/' + topic.title)"
      />
      <Card
        v-for="topic in this.getUnpinnedTopics()"
        :id="topic.id"
        :key="topic.id"
        :title="topic.title"
        :subheading="topic.subheading"
        :last_post="
          topic.last_post ? 'Letzte Postkarte vor ' + topic.last_post : 'Noch keine Postkarten'
        "
        :topic_age="topic.topic_age"
        :pinned="false"
        @click.native="$router.push('/Forum/' + topic.title)"
      />
      <Card
        title="Alle Postkarten anzeigen"
        :noPin="true"
        :noLastPost="true"
        @click.native="$router.push('/Forum')"
      />
    </div>
    <Button
      icon="writePostcard"
      label_text="Postkarte schreiben"
      :is-icon="true"
      class="writePostcard--hubbel"
      @click.native="$router.push('/Tutorial'), sendMatomoEvent()"
    />
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Card from "@/components/Card";
import Api from "@/services/api";
import { getLoggedInUser } from "@/services/loggedIn";
import Button from "@/components/Button";
import { toBoolean } from "../services/utils";

export default {
  name: "Start",
  components: { Card, Footer, Button },
  data() {
    return {
      topics: [],
      topicsAreLoading: true,
      texts: {
        Introduction: {
          textName: "introduction-hubbel",
          textContent: "placeholder for db content",
        },
        Title: { textName: "title", textContent: "" },
      },
    };
  },
  beforeMount() {
    this.getAllTopics();
    (async () => {
      for (let text in this.texts) {
        await Api.getTextByName(this.texts[text].textName).then((res) => {
          let textdb = res.data[0];
          this.texts[text].textContent = textdb.content;
        });
      }
    })();
  },
  methods: {
    sendMatomoEvent() {
      if (!toBoolean(process.env.VUE_APP_USE_MATOMO)) return;
      window._paq.push([
        "trackEvent",
        "CreatePostcard on hubbel",
        "Click 'Write Postcard' Button",
        "'Write Postcard' clicked.",
      ]);
    },
    loggedIn() {
      return getLoggedInUser() !== null;
    },
    getAllTopics() {
      Api.getAllTopics().then((data) => {
        data.data.forEach((topicObj) => this.renderTopic(topicObj));
        this.topicsAreLoading = false;
      });
    },

    renderTopic(topicObj) {
      this.topics.push({
        id: topicObj.topic_id,
        title: topicObj.name,
        subheading: topicObj.subheading,
        pending: !topicObj.approved,
        pinned: topicObj.isSaved,
        has_posts: topicObj.Posts.length !== 0,
        last_post:
          topicObj.Posts.length !== 0
            ? this.renderTime((Date.now() - Date.parse(topicObj.Posts[0].createdAt)) / 1000)
            : "",
        date: topicObj.Posts.length !== 0 ? topicObj.Posts[0].createdAt : topicObj.createdAt,
        topic_age: Math.floor((Date.now() - Date.parse(topicObj.createdAt)) / 1000 / 3600 / 24),
      });
    },
    renderTime(seconds) {
      let time = Math.floor(seconds / (24 * 3600));

      if (time > 30) {
        return "über 30 Tagen";
      }

      if (time < 1) {
        // younger than a day -> time in hours
        time = Math.floor(seconds / 3600);
      } else {
        if (time == 1) {
          return time + " Tag";
        } else {
          return time + " Tagen";
        }
      }

      if (time < 1) {
        // younger than an hour -> time in minutes
        time = Math.floor(seconds / 60);
      } else {
        if (time == 1) {
          return time + " Stunde";
        } else {
          return time + " Stunden";
        }
      }

      if (time < 1) {
        // younger than a minute -> time in seconds
        time = Math.floor(seconds);
      } else {
        if (time == 1) {
          return time + " Minute";
        } else {
          return time + " Minuten";
        }
      }

      if (time > 1) {
        return time + " Sekunden";
      } else {
        return time + " Sekunde";
      }
    },

    getPinnedTopics() {
      let pinnedTopics = [];
      this.topics.forEach((topic) => {
        if (topic.pinned) {
          pinnedTopics.push(topic);
        }
      });
      pinnedTopics.sort(function (a, b) {
        // first show topics with posts, then show empty topics
        // if two topics are empty or not empty, sort them by date
        if (a.has_posts > b.has_posts) return -1;
        if (a.has_posts < b.has_posts) return 1;
        return new Date(b.date) - new Date(a.date);
      });

      return pinnedTopics;
    },
    getUnpinnedTopics() {
      let unpinnedTopics = [];
      this.topics.forEach((topic) => {
        if (!topic.pinned) {
          unpinnedTopics.push(topic);
        }
      });

      unpinnedTopics.sort(function (a, b) {
        // first show topics with posts, then show empty topics
        // if two topics are empty or not empty, sort them by date
        if (a.has_posts > b.has_posts) return -1;
        if (a.has_posts < b.has_posts) return 1;
        return new Date(b.date) - new Date(a.date);
      });

      return unpinnedTopics;
    },
  },
};
</script>
