<template>
  <v-container class="feed-comp feed-comp--hubbel">
    <div v-for="postcard in postcards" :key="postcard.id" class="feed__postcard">
      <div class="postcard-wrapper" :class="{ unApproved: !postcard.approved }">
        <div v-if="!postcard.approved" class="unApproved_note">
          <span v-if="postcard.hidden">Diese Postkarte wurde von der Redaktion abgelehnt!</span>
          <span v-if="!postcard.hidden">Diese Postkarte ist noch nicht freigegeben!</span>
          <br />
        </div>
        <PostCard :postcard_info="postcard" :loggedInUser="null" class="postcard" />
        <QRCodeButton v-if="postcard.approved" :id="postcard.id" class="qrCode" />
      </div>
    </div>
    <div class="feed__end">
      <span v-if="isEndReached">
        Du hast alle Postkarten zu diesem Thema gelesen. Wenn du magst, kannst du selbst eine
        schreiben.
      </span>
      <span v-if="!isEndReached">
        Wenn du diesen Text liest, hat etwas mit dem Nachladen weiterer Postkarten nicht
        funktioniert. Versuche, ein Stück hochzuscrollen oder die Seite neuzuladen, um die
        restlichen Postkarten lesen zu können.
      </span>
    </div>
  </v-container>
</template>
<script>
import PostCard from "@/components/PostCard";
import QRCodeButton from "./QRCodeButton";

export default {
  name: "Feed",
  components: { PostCard, QRCodeButton },
  props: {
    postcards: { type: Array },
    isEndReached: { type: Boolean },
  },
  methods: {
    openModal(postcard) {
      if (postcard.approved) {
        postcard.show = true;
      }
    },

    closeModal(postcard) {
      postcard.show = false;
    },

    openBookmarkModal() {
      var modal = document.getElementById("bookmarkModal");
      modal.style.display = "block";
    },
    closeBookmarkModal(event) {
      event.stopPropagation();
      var modal = document.getElementById("bookmarkModal");
      modal.style.display = "none";
    },
    downloadPostcard(event, index) {
      this.$refs.postcardBar[index].downloadPostcard(event);
    },
    modalPostcardSaved(event, index) {
      this.$refs.postcardBar[index].savePostcard(event);
    },
  },
};
</script>
