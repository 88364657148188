var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forum-view"},[_c('div',{staticClass:"forum-container"},[_c('div',{staticClass:"forum__head shadow color-white",class:{
        'forum__head--collapsed': _vm.forum__head_isCollapsed,
      },attrs:{"id":"forum__head"}},[_c('div',{class:{
          'heading-one': !_vm.forum__head_isCollapsed,
          'heading-two': _vm.forum__head_isCollapsed,
        }},[_vm._v(" "+_vm._s(_vm.topic.name)+" ")])]),_c('div',{staticClass:"forum__content"},[_c('Feed',{ref:"feed",staticClass:"content__feed",attrs:{"id":"forum__content","postcards":_vm.postcards,"isEndReached":_vm.isEndReached},on:{"scroll":_vm.handleScroll}}),_c('div',{staticClass:"feed__info-btn"},[_c('Button',{staticClass:"info-btn__button color-primary",attrs:{"icon":_vm.feed__info_isCollapsed ? 'chevronLeft' : 'chevronRight',"label_text":"","is-cross":""},nativeOn:{"click":function($event){return _vm.toggleBgInfo.apply(null, arguments)}}}),_c('p',{staticClass:"info-btn__heading",class:{ 'd-none': !_vm.feed__info_isCollapsed }},[_vm._v(" Hintergrundinfos ")])],1),_c('div',{staticClass:"feed__info color-light-grey",class:{
          'feed__info--collapsed': _vm.feed__info_isCollapsed,
        }},[_c('p',{staticClass:"heading-three"},[_vm._v("Hintergrundinfos:")]),_c(_vm.topicInformationTransformed,_vm._b({tag:"div"},'div',_vm.$props,false))],1)],1)]),_c('Button',{staticClass:"writePostcard--hubbel",attrs:{"icon":"writePostcard","label_text":"Postkarte schreiben","is-icon":true},nativeOn:{"click":function($event){_vm.$router.push('/Tutorial'), _vm.sendMatomoEvent()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }