import axios from "axios";
import { authHeader } from "@/services/auth-header";
import { toBoolean } from "./utils";

let tempURL;
const domainParts = window.location.href.split("/");
if (domainParts[2].startsWith("localhost:")) {
  // if the app is served using Vue.js, it uses a different port for the backend
  tempURL = "http://localhost:" + 8001 + "/";
} else {
  // tempURL = domainParts[0] + "//" + domainParts[2] + "/";
  tempURL = process.env.VUE_APP_BACKEND_URL; // TODO since the hubbel frontend is deployed on a different server than the backend, we have to hard code the domain here
}

export const baseURL = tempURL;
export const isLocalDebug = baseURL.includes("://localhost"); // completely skips inserting a postcard; use this if neither raspberry pi nor Google Vision is available
export const isLocalDebugOCR = isLocalDebug && toBoolean(process.env.VUE_APP_IS_LOCAL_DEBUG_OCR); // mimics inserting a postcard into a raspberry pi; relies on Google Vision OCR

const Api = axios.create({
  baseURL: baseURL + "api/",
  headers: {
    "Content-type": "application/json",
  },
});

export default {
  async get(addUrl) {
    return await Api.get(addUrl);
  },

  async getNextPostsforTopic(topic_id, limit, offset, startingFrom) {
    return Api.get(
      `nextPostsForTopic/${topic_id}?limit=${limit}&offset=${offset}&startingFrom=${startingFrom}`,
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },

  async getNextPostsFromUser(limit, offset) {
    return Api.get(`nextPostsFromUser?limit=${limit}&offset=${offset}`, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async getNextSavedPosts(limit, offset) {
    return await Api.get(`nextSavedPostsForUser?limit=${limit}&offset=${offset}`, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async getPostById(post_id) {
    return await Api.get(`postById/` + post_id, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async getAllTopics() {
    return Api.get("allTopics", {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async getTopicByName(name) {
    return Api.get("topicByName/" + name);
  },

  async changeProfilePicture(Picture) {
    return Api.post("changeProfilePic", Picture, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },
  async getProfilePicture() {
    return (
      await Api.get("getProfilePic", { headers: { Authorization: `Bearer ` + authHeader() } })
    ).data;
  },

  async postNewPostcard(postcard) {
    return Api.post("postNewPostcard", postcard, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async postNewTopic(name) {
    return Api.post("postNewTopic", { name: name });
  },

  async setTopicPinned(topicId) {
    return Api.get("setTopicPinned/" + topicId, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async setPostSaved(postId) {
    return Api.get("savePost/" + postId, { headers: { Authorization: `Bearer ` + authHeader() } });
  },

  async changeProfileData(form) {
    //Ich weiß, dass die Anfrage absolut sinnlos ist, aber eslint hat gemotzt, dass ich die Variablen nicht nutze
    return await Api.post(`changeProfileData`, form, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async getDebugPostcard() {
    return Api.get("getDebugPostcard", {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async getDebugPostcardTimestamp() {
    return Api.get("getDebugPostcardTimestamp", {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async uploadPostcardImage(postcard) {
     return await Api.post(`uploadPostcardImage`, postcard, {
      headers: {
        Authorization: `Bearer ` + authHeader(),
      },
    });

     //can be used to temporarily deactivate OCR
    /*return {
      data: {
        text: "Aus Testzwecken ist das Auslesen der Postkarte aktuell Deaktiviert",
        sender: "Test Sender",
        image: postcard.scan,
      },
    };*/
  },

  async getUserProfile() {
    return Api.get("getProfileData", {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async test() {
    return Api.get("getProfileData", {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async postHeartbeat(data) {
    return Api.post("postHeartbeat", data);
  },

  async getTextByName(name) {
    return Api.get("textByName/" + name);
  },
};
