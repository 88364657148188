<template>
  <div class="forum-view">
    <div class="forum-container">
      <div
        id="forum__head"
        class="forum__head shadow color-white"
        :class="{
          'forum__head--collapsed': forum__head_isCollapsed,
        }"
      >
        <div
          :class="{
            'heading-one': !forum__head_isCollapsed,
            'heading-two': forum__head_isCollapsed,
          }"
        >
          {{ topic.name }}
        </div>
      </div>

      <div class="forum__content">
        <Feed
          id="forum__content"
          ref="feed"
          class="content__feed"
          :postcards="postcards"
          :isEndReached="isEndReached"
          @scroll="handleScroll"
        />

        <div class="feed__info-btn">
          <Button
            class="info-btn__button color-primary"
            :icon="feed__info_isCollapsed ? 'chevronLeft' : 'chevronRight'"
            label_text=""
            is-cross
            @click.native="toggleBgInfo"
          />
          <p class="info-btn__heading" :class="{ 'd-none': !feed__info_isCollapsed }">
            Hintergrundinfos
          </p>
        </div>

        <div
          class="feed__info color-light-grey"
          :class="{
            'feed__info--collapsed': feed__info_isCollapsed,
          }"
        >
          <p class="heading-three">Hintergrundinfos:</p>
          <div :is="topicInformationTransformed" v-bind="$props" />
        </div>
      </div>
    </div>
    <Button
      icon="writePostcard"
      label_text="Postkarte schreiben"
      :is-icon="true"
      class="writePostcard--hubbel"
      @click.native="$router.push('/Tutorial'), sendMatomoEvent()"
    />
  </div>
</template>

<script>
import Feed from "@/components/Feed";
import Button from "@/components/Button";
import API from "@/services/api";
import { getLoggedInUser } from "@/services/loggedIn";
import { toBoolean } from "@/services/utils";

export default {
  name: "forum_view",
  components: {
    Button,
    Feed,
  },
  data: () => ({
    category: "Event",
    postcards: [],

    forum__head_isCollapsed: false,
    feed__info_isCollapsed: false,
    isEndReached: true,
  }),
  computed: {
    topicInformationTransformed() {
      return {
        template: this.topic.information,
        props: this.$options.props,
      };
    },
  },

  beforeMount() {
    (async () => {
      this.topic = {
        name: "Alle Postkarten",
        topic_id: -1,
      };
      this.topic.information =
        '<div><div>Hier findest Du anstehende Veranstaltungen und Events am Hubland. Dir fehlt ein Event? Dann lass es den hubbel wissen und schreibe es auf eine Postkarte!<br><br>Künftig ist hier auch ein “echter” Kalender geplant, um die Veranstaltungen übersichtlicher darzustellen.<br><br>Zuletzt bearbeitet: 28.07.2022<br>Themenpat*in: <router-link to="/about">hubbel Redaktion</router-link><br><br><br><br><br><br></div></div>';
      document.title = this.topic.name;
      this.getInitialPosts();
    })();
  },

  mounted() {
    document.getElementById("forum__content").addEventListener("scroll", this.handleScroll);
    this.feed__info_isCollapsed = window.innerWidth <= 960; // collapse on mobile
  },

  beforeDestroy() {
    document.getElementById("forum__content").removeEventListener("scroll", this.handleScroll);
    //TODO: https://stackoverflow.com/questions/68588188/can-not-get-element-by-getelementbyid-in-beforedestroy-lifecycle-vue
  },
  methods: {
    sendMatomoEvent() {
      if (!toBoolean(process.env.VUE_APP_USE_MATOMO)) return;
      window._paq.push([
        "trackEvent",
        "CreatePostcard on hubbel",
        "Click 'Write Postcard' Button",
        "'Write Postcard' clicked.",
      ]);
    },
    loggedIn() {
      return getLoggedInUser() !== null;
    },
    updateNav() {
      this.$emit("update_Nav");
    },
    handleScroll() {
      //handle topbar collapse
      const forum__head = document.getElementById("forum__head");
      let forum__content = document.getElementById("forum__content");
      if (this.postcards.length > 1) {
        // kann man bestimmt schöner Lösen, weiß aber gerade nicht wie
        if (forum__content.scrollTop > 100) this.forum__head_isCollapsed = true;
        else if (forum__head.style.height !== "200px") this.forum__head_isCollapsed = false;
      }
      //load more posts
      if (!this.$route.params.post_id) {
        if (
          forum__content.scrollTop + forum__content.clientHeight >=
          forum__content.scrollHeight * 0.75
        ) {
          API.getNextPostsforCategory(
            this.category,
            3,
            this.postcards.length,
            this.postcards[0].id
          ).then((res) => {
            res.data.forEach((postObj) => this.renderPost(postObj));
            this.isEndReached = res.data.length <= 0;
          });
        }
      }
    },

    toggleBgInfo() {
      this.feed__info_isCollapsed = !this.feed__info_isCollapsed;
    },
    renderPost(postObj) {
      if (this.postcards.some((e) => e.id === postObj.post_id)) {
        return;
      }
      function getSender() {
        if (postObj.sender_name) {
          return postObj.sender_name;
        } else if (postObj.user.team && postObj.user.team.length > 0) {
          return postObj.user.team;
        } else if (postObj.user.last_name.length <= 0) {
          return postObj.user.first_name;
        } else {
          return postObj.user.first_name + " " + Array.from(postObj.user.last_name)[0] + ".";
        }
        //TODO maybe keep full name here for moderators
      }
      this.postcards.push({
        id: postObj.post_id,
        readOnly: true,
        approved: postObj.approved,
        category: postObj.category,
        information: postObj.information,
        sender_verified: postObj.user.verified,
        sender_team: postObj.user.team,
        sender: getSender(),
        sender_name_mailbox: postObj.sender_name,
        subject: postObj.topic.name,
        allTopicNames: postObj.allTopicNames,
        text: postObj.text,
        date: postObj.createdAt.split("T")[0] + " " + postObj.createdAt.split(/[T.]/)[1],
        attachments: postObj.Attachments,
        isSaved: postObj.isSaved,
        sentFrom: postObj.sentFrom,
        show: false,
      });
    },

    getInitialPosts() {
      API.getNextPostsforCategory(this.category, 5, 0, -1).then((res) =>
        res.data.forEach((postObj) => this.renderPost(postObj))
      );
    },
  },
};
</script>
