<template>
  <Progressbar :topicId="topicId" @returnPostcard='rememberReturnedPostcard'/>
</template>
<script>
  import Progressbar from "@/components/Progressbar";
  import ScannerApi from "@/services/scanner-api.js";


  export default {
    name: "CreatePostcard",
    components: { Progressbar },
    methods: {
      rememberReturnedPostcard(postcardReturned) {
        this.postcardReturned = postcardReturned;
      },
      acceptPostcard() {
        ScannerApi.acceptPostcard();
      },
    },
    beforeRouteLeave(to, from, next) {
      if (from.path.includes("/createPostcard") && !to.path.includes("/success") && this.postcardReturned == false) {
        this.$confirm({
          title: "Postkarte verwerfen?",
          message:
            "Du hast deine Postkarte noch nicht vollständig abgeschickt. Möchtest du deine Postkarte verwerfen oder sie vervollständigen und abschicken?",
          button: {
            no: "Vervollständigen und abschicken!",
            yes: "Verwerfen!",
          },
          callback: (confirm) => {
            if (confirm) {
              this.acceptPostcard();
              next();
            } else {
              next(false);
            }
          },
        });
      } else {
        next();
      }
    },

    data: () => ({
      topicId: null,
      postcardReturned: false
    }),

    mounted() {
      this.topicId = parseInt(this.$route?.params?.id) || null;
    },


  };
</script>
