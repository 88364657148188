import Vue from "vue";
import VueRouter from "vue-router";
import CreatePostcard from "@/views/CreatePostcard";
import Success from "@/views/Success";
import Start from "@/views/Start";
import Help from "@/views/Help";
import { getLoggedInUser } from "@/services/loggedIn";
import Veranstaltungen from "@/views/Veranstaltungen.vue";
import HistoryHubland from "@/views/HistoryHubland.vue";
import Tutorial from "@/views/Tutorial.vue";

import Forum from "@/views/Forum";
import TermsOfUse from "@/views/TermsOfUse";
import Impressum from "@/views/Impressum";
import DataPrivacy from "@/views/DataPrivacy";
import aboutUs from "@/views/AboutUs";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Start",
    component: Start,
    meta: {
      title: "Hubbel - Startseite",
    },
  },
  {
    path: "/about",
    name: "About",
    component: aboutUs,
    meta: {
      title: "Über uns",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  },
  {
    path: "/nutzungsbedingungen",
    name: "Nutzungsbedingungen",
    component: TermsOfUse,
    meta: {
      title: "Nutzungsbedingungen",
    },
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: Impressum,
    meta: {
      title: "Impressum",
    },
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: DataPrivacy,
    meta: {
      title: "Datenschutz",
    },
  },
  {
    path: "/help",
    name: "Help",
    component: Help,
    meta: {
      title: "Hilfe",
    },
  },
  {
    path: "/veranstaltungen",
    name: "Veranstaltungen",
    component: Veranstaltungen,
    meta: {
      title: "Veranstaltungen",
    },
  },
  {
    path: "/das-hubland",
    name: "HistoryHubland",
    component: HistoryHubland,
    meta: {
      title: "HistoryHubland",
    },
  },
  {
    path: "/createPostcard",
    name: "Postkarte erstellen",
    component: CreatePostcard,
    meta: {
      title: "Postkarte erstellen",
      authRequired: false,
    },
  },
  {
    path: "/createPostcard/:id",
    name: "createPostcard",
    component: CreatePostcard,
    meta: {
      title: "Postkarte erstellen",
      authRequired: false,
    },
  },
  {
    path: "/success",
    name: "Gesendet",
    component: Success,
    meta: {
      title: "Gesendet",
    },
  },
  {
    path: "/forum",
    name: "ForumGesamt",
    component: Forum,
    meta: {
      title: "Forum",
    },
  },
  {
    path: "/forum/:topic",
    name: "Forum",
    component: Forum,
    meta: {},
  },
  {
    path: "/topics",
    name: "Themen",
  },
  {
    path: "/Post/:post_id",
    name: "Post",
    component: Forum,
    meta: {
      title: "Shared Post",
    },
  },

  {
    path: "/Tutorial",
    name: "Anleitung",
    component: Tutorial,
    meta: {
      title: "Anleitung",
    },
  },

  {
    path: "/Tutorial/:id",
    name: "Anleitung",
    component: Tutorial,
    meta: {
      title: "Anleitung",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

router.beforeEach((to, from, next) => {
  //change Title
  document.title = to.meta.title || "Hubbel";

  //authentication
  if (to.meta.authRequired) {
    // console.log("needs Auth" + getLoggedInUser())
    if (getLoggedInUser() === null) {
      next({ name: "Login" });
    } else next();
  } else next();
});

export default router;
