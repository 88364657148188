<template>
  <div class="card-comp">
    <v-card
      class="mx-auto ma-3 card ribbon-wrapper"
      :class="{
        'card--question': isQuestion,
        'card--info': isInfo,
        'card--selected': selected,
      }"
    >
      <v-card-title class="card__title">
        <div class="ribbon ribbon-top-right" :class="{ 'd-none': topic_age < 0 || topic_age > 7 }">
          <span class="ribbon__content">Neues Thema</span>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="card__text">{{ title }}</div>
        <div class="card__subheading">{{ subheading }}</div>
      </v-card-text>
      <v-card-actions class="card__date" :class="{ hide: noLastPost }">
        <p>{{ last_post }}</p>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Api from "@/services/api";
import { getLoggedInUser } from "@/services/loggedIn";

export default {
  name: "Card",
  props: {
    id: {
      type: Number,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    subheading: {
      type: String,
      required: false,
    },
    last_post: {
      type: String,
    },
    topic_age: {
      type: Number,
      default: -1,
    },
    noPin: {
      type: Boolean,
      required: false,
    },
    pinned: {
      type: Boolean,
      required: false,
      default: false,
    },
    noLastPost: {
      type: Boolean,
      required: false,
    },
    isQuestion: {
      type: Boolean,
      required: false,
    },
    isInfo: {
      type: Boolean,
      required: false,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    localPinned: false,
  }),
  created() {
    this.localPinned = this.pinned;
  },
  methods: {
    pinElement(event) {
      event.stopPropagation();
      if (getLoggedInUser()) {
        if (this.id) {
          Api.setTopicPinned(this.id).then((res) => (this.localPinned = res.data));
        }
      } else {
        this.openDialog();
      }
    },
    openDialog() {
      var modal = document.getElementById("pinModal");
      modal.style.display = "block";
    },
    closeDialog(event) {
      event.stopPropagation();
      var modal = document.getElementById("pinModal");
      modal.style.display = "none";
    },
  },
};
</script>
