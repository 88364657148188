<template>
  <div class="custom-page history-hubland-view--hubbel">
    <h1>Das Hubland</h1>
    <div class="custom-page__content">
      <h2>Orte, die man am Hubland kennen sollte</h2>
      <p>
        Diese interaktive Karte markiert wichtige öffentliche Plätze am Hubland, die vor allem für
        neu Zugezogene interessant sein könnten. Fehlt dir ein Ort? Dann lass es uns gerne via Mail
        wissen an hubbelredaktion@protonmail.com!
      </p>
      <div class="iframe_wrapper">
        <div class="iframe_linkhider" />
        <div class="iframe_linkhider2" />
        <iframe
          id="openStreetMapFrame"
          class="iframe_iframe"
          width="100%"
          height="500px"
          frameborder="0"
          allowfullscreen
          src="https://umap.openstreetmap.de/de/map/hubbel-hubland-karte_28851"
        />
      </div>
      <br /><br />

      <h2>Wo kann man (außer am hubbel) Informationen zum Hubland finden?</h2>
      <p>
        <b>Direkt bei der Stadt Würzburg </b> <br />Infos von der Stadt Würzburg unter
        www.wuerzburg-hubland.de abrufen, vor allem zu den Themen Grundstücksverkäufe,
        Planungsprozess, Leben am Hubland, Geschichte und Gewerbe. <br /><br />
        <b>Mainpost</b><br />Auch die Mainpost berichtet regelmäßig über das Hubland<br /><br />
        <b>Informationen aus dem Würzburg Stadtrat</b><br />auf der Webseite vom Würzburger Stadtrat
        (www.wuerzburg.sitzung-online.de) kann man nach „Hubland“ suchen und erhält Vorlagen,
        Sitzungen und Tagesordnungspunkte, bei denen es um das Hubland ging. <br /><br />
        <b>Würzburger Veranstaltungskalender (Stadt Würzburg) </b> <br />Neben Veranstaltungen in
        ganz Würzburg sind im Online-Kalender (www.wuerzburg.de/events-termine/index.html) natürlich
        auch die Veranstaltungen vom Hubland eingetragen. <br /><br />
        <b>Zeitschrift „Eckart“ </b> <br />In der Zeitschrift gibt es manchmal auch interessante
        Infos zum Hubland! Sie liegt meist in der Stadtteilbücherei aus, oder ist als PDF online
        erhältlich. <br /><br />
        <b>Geostadtplan Würzburg</b><br />Die Stadt Würzburg stellt zu einigen Themen (z.B.
        Fahrradwege, Baustellen, öffentliche Toiletten, Bebauungsplan,..) unter
        www.geostadtplan.wuerzburg.de Karten zusammen.
      </p>
      <br /><br />

      <h2>Geschichte des Hublands</h2>
      <h3>Vor 1900</h3>
      <p>
        Wo heute der neue Stadtteil Hubland entsteht, wuchsen im 18. Jahrhundert Kartoffeln. Philipp
        Adam Ulrich (1692-1748) war deutscher Rechtsgelehrter, der seine Professur aufgab und
        Agrarreformer wurde. Er etablierte die Kartoffel als Grundnahrungsmittel. Auf Grund seiner
        Erfolge mit der Kartoffelzucht wurde er auch „der Würzburger Kartoffelprofessor“ genannt.
        <br />
        Aufgrund der günstigen Hochplateau-Lage verlief im Mittelalter eine Fernstraße durch das
        Plangebiet Hubland. Sie entspricht etwa dem Verlauf der heutigen Rottendorfer Straße. Schon
        früh wurde das Gebiet für Hinrichtungen genutzt, weshalb sich im Laufe der Zeit die
        Bezeichnung Galgenberg etablierte.
      </p>

      <h3>Die Fliegerschule Würzburgs am Hubland</h3>
      <p>
        Während des Ersten Weltkriegs - wie schon in den Vorjahren - diente der Galgenberg (früher
        Exerzierplatz Kugelfang) als Landeplatz für Militärflieger auf Überlandflügen. Für sie wurde
        ein neuer, größerer Flugzeugschuppen errichtet, die „Flugzeughalle Würzburg“. Die feierliche
        Einweihung der Fliegerschule, errichtet durch Hauptmann Heinrich Nopitsch, erfolgte am 20.
        Juni 1924. <br />
        Zudem entstand im Verlauf des ersten Weltkriefs ein Kriegsgefangenenlager am Hubland, in dem
        vor allem Franzosen zunächst in Flugzeughallen und Holzbaracken untergebracht waren. Nach
        der militärischen Abrüstung infolge des Versailler Vertrages änderte sich in den
        1920er-Jahren die Bestimmung des Gebietes. Die zuvor sporadische Nutzung als Flugplatz wurde
        nun dominierend. Unter der Führung des Fränkischen Vereins für Luftfahrt entstanden die
        zentrale Landebahn sowie im Osten des Areals Hangars und Wohn- sowie Unterrichtsgebäude.
      </p>

      <h3>NS-Zeit und zweiter Weltkrieg</h3>
      <p>
        In den 1930er-Jahren wurde das Areal zunehmend durch die Wehrmacht genutzt. 1934/35 wurde
        der Flugplatz am Galgenberg offiziell in einen Fliegerhorst der Luftwaffe umgewandelt. Im
        Jahr 1936 diente, mit der Errichtung des Fliegerhorstes, Würzburg der Reichs-Luftwaffe als
        operative Basis. Die Landebahn war eine Graspiste, wo die Flugzeuge je nach Windrichtung
        starten und landen konnten. Der Flugplatz war während des gesamten Zweiten Weltkriegs in
        Betrieb. <br />
        Nach der Eroberung im April 1945 durch die US-Armee wurde das Flugplatzgelände von der
        amerikanischen Armee als Kaserne für eine Infanterie-Division übernommen und das ehemalige
        Flugfeld wurde ab 1950 mit vielen Wohngebäuden für die US-GIs bebaut. Hier entstanden die
        Leighton Barracks. Namensgeber war der gefallene Offizier John A. Leighton.
      </p>

      <h3>US-Armee Stützpunkt</h3>
      <p>
        1951 startete auf dem Galgenberg sowie auf dem höchstgelegenen Teil des Leighton-Areals das
        größte geschlossene Bauprojekt der Nachkriegszeit in Würzburg. 14 neue große Wohnblocks
        entstanden für die amerikanische Siedlung am Flugplatz, der sogenannten »skyline hill«
        einschließlich Elementary School. In den drei heute noch vorhandenen Schul-Gebäudekomplexen
        (im Bereich der Universität „Campus Nord“) wurden in den 80er Jahren mehr als 2.000 Kinder
        aus den Kasernen Würzburgs und der Umgebung unterrichtet. Im März 1951 eröffnete das neue
        Einkaufszentrum, das größte amerikanische Einkaufszentrum in Europa, betrieben für die
        US-Streitkräfte auf dem Leighton-Areal in einem ehemaligen Flugzeughangar. Der gesamte
        Standort entwickelte sich über die Jahre durch die zahlreichen Dienstleistungsangebote
        zunehmend zum Lebens- und Wohnmittelpunkt der US-Streitkräfte (Filmtheater, Kirche, Postamt,
        Bibliothek und weitere Einrichtungen).
      </p>
      <h3>Vor 1900</h3>
      <p>
        2008 zogen die US-amerikanischen Streitkräfte vom Hubland ab und Anfang 2009 erfolgte die
        Freigabe der Leighton Barracks an die Stadt Würzburg. Nach Abzug der US-Streitkräfte gingen
        die amerikanischen Militärflächen in das allgemeine Grundvermögen der Bundesrepublik
        Deutschland über. Damit fallen sie in den Zuständigkeitsbereich der Bundesanstalt für
        Immobilienaufgaben (BImA). Die Planungshoheit über diese Flächen liegt bei der Stadt
        Würzburg, der sich so die einmalige und herausragende Chance zu einer zielgerichteten und
        nachhaltigen Stadtentwicklung eröffnet. <br />
        In den ehemaligen Baracken siedelte die Universität Würzburg den Campus „Hubland Nord“ an
        und baute sie zu Institutsgebäuden, Laboren und Seminarräumen aus. <br />
        Im August 2017 zogen die ersten Bewohner in den neuen Stadtteil Hubland. 2018 fand die
        Landesgartenschau am Hubland statt. Diese war der Startschuss für die Entstehung von sieben
        Quartieren Wohnraum für bis zu 4.500 Bürger*innen. <br /><br />
        Detaillierte Infos finden sich im Buch von Roland Flade: „Würzburgs neuer Stadtteil Hubland“
        – seine Geschichte vom 18. bis 21. Jahrhundert (ISBN 0978-3-87717-854-6)
      </p>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "Help",
  components: { Footer },
};
</script>
