import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import "../../client-shared/scss/style.scss";
import VueCroppie from "vue-croppie";
import VueQr from "vue-qr";
import "croppie/croppie.css"; // import the croppie css manually
import VueSocialSharing from "vue-social-sharing";
import VueHtml2pdf from "vue-html2pdf";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import VueConfirmDialog from "vue-confirm-dialog";
import IdleVue from "idle-vue";
import VueMatomo from "vue-matomo";
import { toBoolean, toNumber } from "./services/utils";
import "@fontsource/roboto";

Vue.use(VueCroppie);
Vue.use(VueSocialSharing);
Vue.use(VueHtml2pdf);
Vue.use(VueViewer);
Vue.use(VueConfirmDialog);
Vue.use(VueQr);
if (toBoolean(process.env.VUE_APP_USE_MATOMO)) {
  Vue.use(VueMatomo, {
    host: process.env.VUE_APP_MATOMO_HOST,
    siteId: toNumber(process.env.VUE_APP_MATOMO_SITEID),
    trackerFileName: "matomo",
    router: router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: false,
    enableHeartBeatTimer: false,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    preInitActions: [],
  });
}

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 300000,
});

Vue.config.productionTip = false;
new Vue({
  vuetify,
  router,
  render: (h) => h(App),
  onIdle() {
    this.$router.push("/");
  },
}).$mount("#app");

if (toBoolean(process.env.VUE_APP_USE_MATOMO)) {
  window._paq.push(["trackPageView"]);
}
