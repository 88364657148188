var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"shadow postcard-comp",attrs:{"action":""}},[_c('div',{staticClass:"postcard__wrapper"},[(!_vm.attachmentShown)?_c('div',{staticClass:"postcard__front"},[_c('div',{staticClass:"postcard__left"},[_c('div',[_c('p',{staticClass:"postcard__message font-regular"},[(_vm.postcard_info.approved || _vm.isInWork)?_c('span',[_vm._v(_vm._s(_vm.postcard_info.text)+" ")]):_c('span',{staticClass:"dummyText"},[_vm._v(" "+_vm._s(_vm.dummyText)+" ")])])])]),_c('div',{staticClass:"postcard__vertical-line"}),_c('div',{staticClass:"postcard__right"},[_c('div',{staticClass:"postcard__date"},[_c('ul',[_c('li',[_vm._v(" "+_vm._s(_vm.getDateString(_vm.postcard_info.date))+" ")]),_c('li',[(
                  _vm.postcard_info.readOnly &&
                  ['PC', 'Phone', 'Mailbox'].includes(_vm.postcard_info.sentFrom)
                )?_c('v-img',{staticClass:"date__img date__img--stamp",attrs:{"alt":'Created from ' + _vm.postcard_info.sentFrom,"contain":"","src":require(`../../../client-shared/img/stamp_${_vm.postcard_info.sentFrom.toLowerCase()}.svg`)}}):_vm._e(),_c('v-img',{staticClass:"date__img date__img--logo",attrs:{"alt":"hubbel Logo","contain":"","src":require("../../../client-shared/img/hubbel_logo.svg")}})],1)])]),_c('div',{staticClass:"postcard__sender"},[_c('ul',[_c('li',[_vm._v("Absender*in")]),_c('li',[_c('div',[_c('b',{class:'verified--' + _vm.postcard_info.sender_verified},[_vm._v(_vm._s(_vm.postcard_info.sender)+" "),(_vm.postcard_info.sender_verified)?_c('v-img',{staticClass:"verified-icon",attrs:{"src":require("../../../client-shared/img/verification_hubbel_v01.svg"),"alt":"(verified)","width":"18","tabindex":"1"}}):_vm._e()],1)])])])]),(this.postcard_info.subject)?_c('div',{staticClass:"postcard__subject"},[_c('ul',[_c('br'),_c('li',[_vm._v("Thema:")]),_c('li',[_c('div',[_c('router-link',{staticClass:"subject__link",attrs:{"to":`/Forum/${_vm.postcard_info.subject}`}},[_c('b',[_vm._v(_vm._s(_vm.postcard_info.subject))])])],1)])])]):_vm._e()])]):_vm._e(),(_vm.attachmentShown)?_c('div',{staticClass:"postcard__back"},[_c('Attachment',{attrs:{"attachments":_vm.attachments,"postcard_id":_vm.postcard_info.id,"read-only":_vm.postcard_info.readOnly},on:{"deleteAttachment":_vm.deleteAttachment,"openInput":function($event){return _vm.openInput()}}})],1):_vm._e()]),(!_vm.postcard_info.readOnly || (_vm.attachments.length > 0 && _vm.postcard_info.approved))?_c('Button',{staticClass:"postcard__attachment-switcher",attrs:{"id":"attachmentSwitcher","is-anhang":true,"icon":"paperclip","label_text":_vm.attachmentShown
        ? 'Postkarte anzeigen'
        : _vm.attachments.length === 0
        ? 'Anhang hinzufügen'
        : ' Postkarte anzeigen'},nativeOn:{"click":function($event){return _vm.toggleAttachment($event)}}}):_vm._e(),(!_vm.postcard_info.readOnly)?_c('input',{staticClass:"fileChooser",attrs:{"id":"fileChooser","type":"file","accept":"image/*,.pdf","multiple":""},on:{"change":function($event){return _vm.uploadAttachments()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }