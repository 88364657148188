<template>
  <v-stepper
    v-model="e1"
    alt-labels
    class="stepper elevation-0 progress-bar-comp progress-bar-comp--hubbel"
  >
    <div class="progress-bar__header-wrapper">
      <v-stepper-header class="progress-bar__header">
        <v-stepper-step :complete="e1 > 1" step="1">Postkarteneinwurf</v-stepper-step>

        <v-divider />

        <v-stepper-step :complete="e1 > 2" :editable="e1 > 2" step="2">
          Text prüfen
        </v-stepper-step>

        <v-divider />

        <!--<v-stepper-step :complete="e1 > 3" :editable="e1 > 3" step="3"> Thema </v-stepper-step>

        <v-divider />-->

        <v-stepper-step :complete="e1 > 3" :editable="e1 > 3" step="3"> Kategorie </v-stepper-step>

        <v-divider />

        <v-stepper-step step="4">Absenden</v-stepper-step>
      </v-stepper-header>
    </div>

    <v-stepper-items class="progress-bar__content">
      <v-stepper-content step="1" class="content__step--scanning">
        <h2 class="content__heading">Ich lese nun deine Postkarte. Bitte warte kurz.</h2>
        <img
          id="wheel"
          class="content-scanning__wheel"
          src="../../../client-shared/img/wheel.svg"
          height="600"
          alt="Drehendes Zahnrad"
        />
      </v-stepper-content>

      <v-stepper-content step="2" class="content__step--check-scan">
        <div v-if="isTextGood" id="checkPostcard">
          <div class="turnaround" :class="{ 'turnaround--turned': this.showScan }">
            <PostCard
              ref="verifyPostcard"
              :postcard_info="Object.assign({ readOnly: true }, PostCard_Info)"
              class="content__postcard turnaround__front"
              :isInWork="true"
            />
            <img
              alt="Scan deiner Postkarte"
              class="content__postcard-image shadow turnaround__back"
              v-bind:src="this.image_base64"
            />
          </div>
          <Button
            :is-icon="true"
            :icon="showScan ? 'mdi-image-off-outline' : 'mdi-image-outline'"
            :label_text="showScan ? 'Postkarte anzeigen' : 'Bild anzeigen'"
            class="postcard__attachment-switcher mb-3"
            @click.native="showScan = !showScan"
          />

          <h2>Habe ich den Text deiner Postkarte richtig erkannt?</h2>

          <div class="content-check-scan__answers-row">
            <button
              class="small"
              @click="
                e1 = 3;
                sendMatomoEvent(1.1);
              "
            >
              Ja
            </button>
            <button
              class="small"
              @click="
                textIsBad();
                sendMatomoEvent(1.2);
              "
            >
              Nein
            </button>
          </div>
        </div>
        <div v-if="!isTextGood" id="badText">
          <p class="content-check-scan__bad-options-text">
            Oh nein, da habe ich mich wohl leider verlesen. Du hast jetzt zwei Möglichkeiten:
          </p>
          <p class="content-check-scan__bad-options-text">
            1. Du schickst die Postkarte trotzdem ab. Die Redaktion wird sich die Postkarte nochmal
            genauer anschauen und mir beim Lesen helfen.
          </p>
          <p class="content-check-scan__bad-options-text">
            2. Du kannst deine Postkarte nochmal schreiben. Achte dabei darauf, besonders deutlich
            zu schreiben.
            <!-- TODO "2. Du kannst deine Postkarte zurückbekommen und selbst überarbeiten. Dabei verwirfst du den aktuellen Prozess." -->
          </p>
          <h1>Was möchtest du tun?</h1>
          <div class="content-check-scan__answers-row">
            <button
              @click="
                e1 = 3;
                sendMatomoEvent(1.21);
              "
            >
              Die Redaktion soll meine Postkarte ausbessern.
            </button>
            <button
              @click="
                returnPostcardToUser();
                $router.push('/');
                sendMatomoEvent(1.22);
              "
            >
              Ich möchte die Postkarte selber ausbessern.
            </button>
          </div>
          <div class="content__button-row">
            <Button :is-back="true" class="button-row__back" @click.native="isTextGood = true" />
          </div>
        </div>
      </v-stepper-content>

      <v-stepper-content step="3" class="content__step--topic">
        <h2 class="content__heading">Zu welchem Thema passt dein Beitrag am besten?</h2>

        <div class="content__topic-grid">
          <DashedCard
            v-if="withoutTopicId >= 0"
            title="Ohne Thema"
            :selected="selectedTopic.id === withoutTopicId"
            @click.native="
              selectedTopic = { id: withoutTopicId, title: 'Ohne Thema', approved: false };
              toFourthStep();
              sendMatomoEvent(2.0);
            "
          />
          <Card
            v-for="topic in getApprovedTopics()"
            :id="topic.id"
            :key="topic.id"
            :v-if="topic.approved === true"
            :title="topic.title"
            :no-pin="true"
            :no-last-post="true"
            :selected="selectedTopic?.id === topic.id || false"
            @click.native="
              selectedTopic = topic;
              toFourthStep();
              sendMatomoEvent(2.0);
            "
          />
        </div>

        <div class="content__button-row">
          <Button :is-back="true" class="button-row__back" @click.native="e1 = 2" />
          <Button
            :is-primary="true"
            label_text="Weiter"
            :disabled="this.selectedTopic.id !== null ? false : 'disabled'"
            class="button-row__next"
            @click.native="
              toFourthStep();
              sendMatomoEvent(2.0);
            "
          />
        </div>
      </v-stepper-content>

      <!--<v-stepper-content step="4" class="content__step--category">
        <h2 class="content__heading">Zu welcher Kategorie gehört Ihr Beitrag?</h2>
        <v-stepper-content step="4" class="content__step--category">
          <h2 class="content__heading">Zu welcher Kategorie gehört Ihr Beitrag?</h2>
          <div class="content__category-grid">
            <Card v-for="categoryName in getCategories()"
                  :title="categoryName"
                  :key="categoryName"
                  :is-info="true"
                  :no-last-post="true"
                  :no-pin="true"
                  :selected="category === categoryName"
                  class="category-grid__item"
                  @click.native="
      category = categoryName;
      toThirdStep();
      sendMatomoEvent(2);
    " />
          </div>
          <div class="content__button-row">
            <Button :is-back="true" class="button-row__back" @click.native="e1 = 3" />
          </div>
        </v-stepper-content>
        -->

      <v-stepper-content step="4" class="content__step--check">
        <h2 class="content__heading">Überprüfe deine Postkarte nochmal vor dem Absenden.</h2>

        <PostCard
          ref="verifyPostcard"
          :postcard_info="Object.assign({ readOnly: true }, PostCard_Info)"
          class="content__postcard"
          :isInWork="true"
        />
        <div class="content__button-row">
          <Button :is-back="true" class="button-row__back" @click.native="e1 = 3" />
          <Button
            :is-icon="true"
            :icon="this.isSubmitting ? 'mdi-email-fast-outline' : 'mdi-email-fast'"
            label_text="Absenden"
            class="button-row__next"
            :disabled="this.isSubmitting ? 'disabled' : false"
            @click.native="
              acceptPostcard();
              sendCard();
              sendMatomoEvent(4.0);
            "
          />
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import DashedCard from "./DashedCard";
import Card from "./Card";
import Button from "./Button";
import PostCard from "./PostCard";
import Api, { isLocalDebug, isLocalDebugOCR } from "@/services/api.js";

import ScannerApi from "@/services/scanner-api.js";

import { getLoggedInUser } from "@/services/loggedIn";
import { toBoolean } from "@/services/utils";

export default {
  name: "Progressbar",
  components: {
    PostCard,
    Button,
    Card,
    DashedCard,
  },
  props: {
    topicId: {
      type: Number,
      required: false,
    },
    /*extractedText: {
        type: String,
        required: true,
      }*/
  },
  data() {
    return {
      e1: 1,
      newTopic: "",
      selectedTopic: { id: null, title: null, approved: null },
      category: null,
      text: "",
      attachments: [],
      dialog: false,
      topics: [],
      user: null,
      image: "",
      isTextGood: true,
      showScan: false,
      isSubmitting: false,
      image_base64: "",
      sender_name: "",
      withoutTopicId: process.env.VUE_APP_WITHOUT_TOPIC_ID || -1, // database id for when no specific topic is selected
    };
  },

  computed: {
    PostCard_Info() {
      return {
        information: this.category === "information",
        sender: this.sender_name,
        category: this.category,
        subject: this.getTitle(),
        text: this.text,
        attachments: this.attachments,
        date: this.getCurrentDate(),
        sentFrom: "Mailbox",
      };
    },
  },

  created() {
    this.getTopics();
    let tempUser = getLoggedInUser();
    //TODO at this point, we might just get the hubbel account data instead of having to log it in
    if (tempUser) {
      this.user = JSON.parse(tempUser);
    }
  },

  mounted() {
    this.sendMatomoEvent(0.0);
    this.getLastPostcard();
  },
  methods: {
    sendMatomoEvent(step) {
      if (!toBoolean(process.env.VUE_APP_USE_MATOMO)) return;
      console.log("step", step);
      switch (step) {
        case 0.0:
          window._paq.push([
            "trackEvent",
            "CreatePostcard on hubbel",
            "Read scanned Postcard",
            "Step 0.0: Postcard inserted into Scanner",
          ]);
          break;
        case 1.1:
          window._paq.push([
            "trackEvent",
            "CreatePostcard on hubbel",
            "Click Yes Button",
            "Step 1.1: Verify identified Text from Postcard",
          ]);
          break;
        case 1.2:
          window._paq.push([
            "trackEvent",
            "CreatePostcard on hubbel",
            "Click No Button",
            "Step 1.2: Disagree identified Text from Postcard",
          ]);
          break;
        case 1.21:
          window._paq.push([
            "trackEvent",
            "CreatePostcard on hubbel",
            "Click 'Delegate to Editorial Department' Button",
            "Step 1.21: Delegate to Editorial Department",
          ]);
          break;
        case 1.22:
          window._paq.push([
            "trackEvent",
            "CreatePostcard on hubbel",
            "Click 'Write new Postcard' Button",
            "Step 1.22: Start over and write new Postcard",
          ]);
          break;
        case 2.0:
          window._paq.push([
            "trackEvent",
            "CreatePostcard on hubbel",
            "Postcard Topic Selected",
            "Step 2.0: Select a Postcard Topic",
          ]);
          break;
        case 3.0:
          window._paq.push([
            "trackEvent",
            "CreatePostcard on hubbel",
            "Postcard Type Selected",
            "Step 3.0: Select a Postcard Type",
          ]);
          break;
        case 4.0:
          console.log("Postcard submit");
          window._paq.push([
            "trackEvent",
            "CreatePostcard on hubbel",
            "Postcard Submit",
            "Step 4.0: Postcard submitted",
          ]);
          break;
        default:
          break;
      }
    },
    getTitle() {
      return this.selectedTopic.title;
    },
    getTopics() {
      Api.getAllTopics().then((data) => {
        data.data.forEach((topicObj) => {
          this.addTopic(topicObj);
        });
        this.selectTopic();
      });
    },
    addTopic(topicObj) {
      this.topics.push({
        id: topicObj.topic_id,
        title: topicObj.name,
        approved: topicObj.approved,
        pinned: false,
        date:
          topicObj.Posts && topicObj.Posts.length !== 0
            ? topicObj.Posts[0].createdAt
            : topicObj.createdAt,
      });
    },
    selectTopic() {
      if (this.topicId) {
        this.topics.forEach((topic) => {
          if (topic.id == this.topicId) {
            this.selectedTopic = topic;
          }
        });
      }
    },
    getApprovedTopics() {
      let approvedTopics = [];
      this.topics.forEach((topic) => {
        if (topic.approved) {
          approvedTopics.push(topic);
        }
      });

      approvedTopics.sort((a, b) => {
        if (this.selectedTopic?.id === a.id) return -1;
        if (this.selectedTopic?.id === b.id) return 1;
        return a.title.localeCompare(b.title);
      });

      return approvedTopics;
    },

    getCategories() {
      let categories = new Array("Question", "Information", "Event");
      return categories;
    },

    toSecondStep() {
      this.e1 = 2;
    },

    toThirdStep() {
      if (this.selectedTopic.id === null) {
        alert("Bitte wähle ein Thema!");
      } else {
        this.e1 = 3;
      }
    },

    toFourthStep() {
      this.e1 = 4;
    },
    toFifthStep() {
      if (this.category === null) {
        alert("Bitte wähle eine Kategorie!");
      } else {
        this.e1 = 5;
      }
    },
    textIsBad() {
      this.isTextGood = false;
    },
    sendCard() {
      this.isSubmitting = true;
      const Postcard = {
        text: this.text,
        attachments: this.attachments,
        topic: this.selectedTopic,
        sendFrom: "Mailbox",
        information: this.category === "information",
        sender_name: this.sender_name,
        category: this.category
      };
      Api.postNewPostcard(Postcard).then((res) => {
        if (res.data) this.$router.push("/success");
      });
    },
    getTopic() {
      return this.selectedTopic;
    },
    getCurrentDate() {
      // this formats the datetime as it is returned by the database,
      // so it can be handled the same way by following methods
      // "2022-02-13 05:06:42"
      const date = new Date();
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const hour = ("0" + date.getHours()).slice(-2);
      const minute = ("0" + date.getMinutes()).slice(-2);
      const second = ("0" + date.getSeconds()).slice(-2);
      return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
    },
    returnPostcardToUser() {
      this.$emit('returnPostcard', true);
      ScannerApi.rejectPostcard().then((res) => {
        if (res === true) {
          this.$router.push("/createPostcard");
        }
      });
      /*ScannerApi.returnPostcardDebug().then((res) => {
        console.log(res);
      });*/
    },
    acceptPostcard() {
      ScannerApi.acceptPostcard();
    },
    getLastPostcard() {
      if (isLocalDebug && !isLocalDebugOCR) {
        this.skipScanner();
      } else if (isLocalDebug && isLocalDebugOCR) {
        ScannerApi.getLatestPostcardDebug().then((res) => {
          const postcard = {
            scan: res.base64,
          };

          Api.uploadPostcardImage(postcard).then((res) => {
            this.text = res.data.text;
            this.sender_name = res.data.sender;
            this.attachments[0] = "data:image/jpg;base64," + res.data.image; // to store
            this.image_base64 = "data:image/jpg;base64," + res.data.image; // to display
            this.toSecondStep();
          });
        });
      } else {
        ScannerApi.getLatestPostcard().then((res) => {
          var base64 = res.data;
          const postcard = {
            scan: base64,
          };
          Api.uploadPostcardImage(postcard).then((res) => {
            this.text = res.data.text;
            this.sender_name = res.data.sender;
            this.attachments[0] = "data:image/jpg;base64," + res.data.image; // to store
            this.image_base64 = "data:image/jpg;base64," + res.data.image; // to display
            this.toSecondStep();
          });
        });
      }
    },
    skipScanner() {
      this.text = "Testtext für lokales Debugging.";
      this.sender_name = "Debugger";
      this.toSecondStep();
    },
  },
};
</script>
